.service-box {
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(85, 85, 85, 0.1);
    border-radius: 6px;
    transition: all 0.5s;
    margin-bottom: 6px;
    .cover-img-container {
        background-repeat: no-repeat;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 6px 6px 0px 0px;
        position: relative;
    }
    .cover-img {
        border-radius: 6px 6px 0px 0px;
        transition: all 0.5s;
        width: 100%;
        height: 100%;
        transform: scale(1);
    }
    h3 {
        color: #404550;
    }
    .service-box-actions {
        border-top: 1px solid #ecebed;
    }
    .service-box-desc {
        color: #789;
        margin-top: 2px;
    }
    .service-box-price {
        color: #404550;
    }
    .preview-but {
        border: 1px solid #eee;
        border-radius: 3px;
        // transition: all 0.4s ease-in-out 0s;
        position: relative;
        color: #404550;
        p {
            // z-index: 1;
            transition: all 0.5s;
            position: relative;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(#677dc4, #7974C0);
            z-index: 0;
            border-radius: 3px;
            opacity: 0;
            transition: all 0.4s ease-in-out 0s;
        }
    }
    &:hover {
        box-shadow: 0px 0px 15px 0px rgba(85, 85, 85, 0.2);
        .preview-but {
            color: #fff;
            border-color: #fff;
            &::before {
                opacity: 1;
            }
        }
        .cover-img {
            transform: scale(1.1);
        }
    }
}