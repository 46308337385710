.singleThing {
    .thingInfoList {
        .thingInfoTitle {
            flex: 1;
            color: #000510;
            font-weight: bold;
        }
        .thingInfoValue {
            flex: 3;
            color: #000510;
        }
    }
    .thingsTabs {
        background-color: #fff;
        padding: 9px 20px;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #eaecef;
        border-bottom: none;
        transition: all 0.5s;
        color: #789;
        cursor: pointer;
        font-size: 13px;
        margin-right: -1px;
        margin-left: 0px;
        &:first-of-type {
            border-radius: 10px 0px 0px 0px;
        }
        &:last-of-type {
            border-radius: 0px 10px 0px 0px;
        }
        // font-weight: bold;
        // margin-left: -20px;
        span {
            word-wrap: none;
            white-space: nowrap;
        }
        img {
            opacity: 0.4;
        }
        &:hover {
            background-color: #33445520;
            box-shadow: 1px 1px 15px #33445510 inset;
        }
        &.active {
            background-color: #345;
            color: #fff;
            border-color: transparent;
            img {
                filter: invert(100%);
                opacity: 0.9;
            }
        }
    }
    .thingInfoPage {
        background-color: #fff;
        border: 1px solid #f2f6f8;
        border-radius: 0px 0px 10px 10px
    }
}