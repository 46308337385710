//////////
.default-filter-view {
    .selectSearch {
        font-size: 13px;
        // text-align: center;
        padding: 5px 15px;
        margin: 5px;
        height: 35px;
        background-color: #fff;
        border-radius: 30px;
        border-width: 1px;
        border-style: solid;
        border-color: #eee;
        margin-top: 5px;
    }
}

//////searchbar
.searchbar {
    height: 34px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0px 0px;
    border: 1px solid #e4eaf2;
    box-shadow: 0px 0px 15px #677dc410;
    transition: all 0.5s;
    .search-but {
        border-radius: 4px 8px 8px 4px;
        width: 28px;
        height: 28px;
        padding: 4px;
        transition: all 0.5s;
        padding: 5px;
        margin: 3px 3px;
        .rtl & {
            border-radius: 8px 4px 4px 8px;
        }
        img {
            flex: 1;
            height: 100%;
            width: 100%;
            object-fit: contain;
            transition: all 0.5s;
        }
    }
    input {
        background-color: transparent;
        border: none;
        padding: 5px 15px;
        font-size: 13px;
        outline: none;
        &::placeholder {
            color: #aab;
            transition: all 0.5s;
        }
        &:focus {}
    }
    .search-but {
        opacity: 0.2;
        transition: all 0.5s;
    }
    &:hover {
        box-shadow: 0px 0px 30px #677dc420;
        .search-but {
            opacity: 0.8;
            padding: 5px;
            background-color: #677dc4;
            img {
                filter: invert(100%);
            }
        }
        input::placeholder {
            color: #789;
        }
    }
    &.isFocuced {
        box-shadow: 0px 0px 30px #677dc420;
        .search-but {
            opacity: 0.8;
            background-color: #677dc4;
            padding: 5px;
            img {
                filter: invert(100%);
            }
        }
        input::placeholder {
            color: #789;
        }
    }
}

/////////////
.from-to-input-container {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 13px;
    input {
        font-size: 13px;
    }
}

.from-to-but {
    // align-self: stretch;
    background: #e2e6e8;
    border-radius: 4px;
    padding: 3px 8px;
    transition: all 0.5s;
    &:hover {
        background-color: #234;
        img {
            filter: invert(100%);
        }
    }
}

/////////////
.explore-filter-but {
    border-radius: 4px;
    padding: 5px 10px;
    background-color: #f2f6f8;
    color: #345;
    transition: all 0.5s;
    &.active-filter {
        background-color: #234;
        color: #fff;
    }
}

//////////
.ripple-but {
    border-radius: 4px;
    padding: 8px 15px;
    text-decoration: none;
    color: #fff;
    border: 1px solid #677dc4;
    color: #677dc4;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.ripple-but:hover {
    color: #fff;
}

.ripple-back {
    position: absolute;
    transition: width 0.5s, height 0.5s;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 0px;
    left: 0px;
    width: 0px;
    height: 0px;
}

//////////////
.section-base {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-right: auto;
    margin-left: auto;
}

.button-light-hover {
    transition: all 0.5s;
}

.button-light-hover:hover {
    background-color: #f2f6f8 !important;
}

.button-light-hover:active {
    background-color: #d2eaee !important;
}

.gallary {
    .gallary-arrow {
        opacity: 0.3;
        transition: all 0.5s;
        &:hover {
            opacity: 0.9;
        }
    }
    .gallary-image {
        opacity: 0.6;
        transition: all 0.5s;
        &:hover {
            opacity: 1;
        }
        &.active {
            opacity: 1;
        }
    }
}

.transition-all {
    transition: all 0.5s;
}

.hover-back:hover {
    background-color: #f2f6f8;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 0px white inset !important;
    -webkit-text-fill-color: #333;
}

@-webkit-keyframes autofill {
    0%,
    100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.page-loader table {
    table-layout: fixed;
    width: 100%;
}

.page-loader figure {
    margin: 0px;
}

.page-loader img {
    width: 100% !important;
}

.page-loader table {
    border-spacing: 0em;
    margin-top: 15px;
    margin-bottom: 25px;
}

.page-loader td {
    border: 1px solid #f2f6f8;
    margin: 0px;
    padding: 5px 10px;
}

.page-loader tr:nth-child(even) {
    background-color: #f6f8fa;
}

.rtl {
    .page-loader {
        direction: rtl;
    }
}

.ltr {
    .page-loader {
        direction: ltr;
    }
}

.center-marker {
    width: 25px;
    height: 43px;
    margin-top: -43px;
    margin-left: -12.5px;
    top: 50%;
    left: 50%;
    position: absolute;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTExLjUgMGgxLjkyQzE5LjQ4LjQ1IDI0LjU4IDUuNjIgMjUgMTEuNjh2MS41OGMtLjcyIDcuOTYtOC41NiAxMi42My0xMC4yOCAyMC4xOS0uNDEgMS42MS0uNjUgMy4yNi0uODcgNC45MS0uMjUuODctLjc0IDIuNjItLjk4IDMuNDktMS4wNC0uOTEtMS41OS0yLjA3LTEuNjQtMy40Ny0uNTctMy41Ni0xLjI4LTcuMjEtMy4zNC0xMC4yNUM0LjgxIDIzLjU4Ljc2IDE5LjI3IDAgMTMuNjF2LTEuOTRDLjM3IDUuNjQgNS40OC40NSAxMS41IDBtLTEuMiA4LjMyYy0zLjA2IDEuMzYtMy4yOSA2LjExLS42NCA4IDIuNjggMi4wNSA3LjQyLjIzIDcuNC0zLjM0LjY3LTMuNjMtMy42MS02LjM5LTYuNzYtNC42NnoiIGZpbGw9IiNlYTM2MzciLz48cGF0aCBkPSJNOC44IDQwLjQ0Yy43OC0uNzEgMS41OS0xLjQgMi40My0yLjA2LjA1IDEuNC42IDIuNTYgMS42NCAzLjQ3LjI0LS44Ny43My0yLjYyLjk4LTMuNDkuODkuNyAxLjc1IDEuNDQgMi41OCAyLjIxLS4yNC44NC0uNjIgMS42My0uOTYgMi40M0g5LjY4Yy0uMzEtLjg1LS42Ni0xLjY4LS44OC0yLjU2eiIgb3BhY2l0eT0iLjE4Ii8+PC9zdmc+");
}

.marker {
    width: 25px;
    height: 43px;
    // transform: translate(12px, 43px);
    filter: grayscale(100%);
    // margin-top: -43px;
    // margin-left: -12.5px;
    top: 0;
    left: 0;
    position: absolute;
    background-size: cover;
    // width: 50px;
    // height: 50px;
    // border-radius: 50%;
    // cursor: pointer;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTExLjUgMGgxLjkyQzE5LjQ4LjQ1IDI0LjU4IDUuNjIgMjUgMTEuNjh2MS41OGMtLjcyIDcuOTYtOC41NiAxMi42My0xMC4yOCAyMC4xOS0uNDEgMS42MS0uNjUgMy4yNi0uODcgNC45MS0uMjUuODctLjc0IDIuNjItLjk4IDMuNDktMS4wNC0uOTEtMS41OS0yLjA3LTEuNjQtMy40Ny0uNTctMy41Ni0xLjI4LTcuMjEtMy4zNC0xMC4yNUM0LjgxIDIzLjU4Ljc2IDE5LjI3IDAgMTMuNjF2LTEuOTRDLjM3IDUuNjQgNS40OC40NSAxMS41IDBtLTEuMiA4LjMyYy0zLjA2IDEuMzYtMy4yOSA2LjExLS42NCA4IDIuNjggMi4wNSA3LjQyLjIzIDcuNC0zLjM0LjY3LTMuNjMtMy42MS02LjM5LTYuNzYtNC42NnoiIGZpbGw9IiNlYTM2MzciLz48cGF0aCBkPSJNOC44IDQwLjQ0Yy43OC0uNzEgMS41OS0xLjQgMi40My0yLjA2LjA1IDEuNC42IDIuNTYgMS42NCAzLjQ3LjI0LS44Ny43My0yLjYyLjk4LTMuNDkuODkuNyAxLjc1IDEuNDQgMi41OCAyLjIxLS4yNC44NC0uNjIgMS42My0uOTYgMi40M0g5LjY4Yy0uMzEtLjg1LS42Ni0xLjY4LS44OC0yLjU2eiIgb3BhY2l0eT0iLjE4Ii8+PC9zdmc+");
}

.mapboxgl-ctrl-attrib-inner {
    display: none !important;
}

canvas {
    outline: none;
}

.body-no-sroll {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    height: 100vh;
}

.profileImageInput {
    .profileImageButton {
        background-color: #f2f6f8;
        border: 1px solid #f0f2f4;
        border-radius: 30px;
        padding: 7px 20px;
        box-shadow: inset 0 0 5px #007aff10;
    }
    .profileImageRemoveButton {
        color: #ee5050;
        font-size: 12px;
    }
}

.profileImageInputContainer {
    object-fit: cover;
    width: 120px;
    height: 120px;
    position: relative;
}

.profileImage {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.isDragging {
    .draggable-component {
        // pointer-events: none;
        .drag-indicator {
            // pointer-events: none;
            opacity: 0 !important;
            background-image: none !important;
        }
    }
}

.draggable-component {
    position: relative;
    &:hover {
        .drag-indicator {
            opacity: 0.1;
        }
    }
    &:active {
        // background-color: #ee5050;
        .drag-indicator {
            background-image: none;
            opacity: 0 !important;
        }
    }
    // .hide {
    //     opacity: 0 !important;
    //     pointer-events: none;
    //     // display: none;
    // }
    .drag-indicator {
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        background-image: url("/images/drag-indicator.png");
        width: 10px;
        position: absolute;
        // overflow: hidden;
        top: 8px;
        left: 2px;
        opacity: 0;
        transition: opacity 0.2s;
        z-index: 4;
        cursor: grab;
        .rtl & {
            left: auto;
            right: 2px;
        }
        // &:hover {
        //     opacity: 0.1;
        // }
        // &:active {
        //     background-image: none;
        //     opacity: 0 !important;
        // }
        // &:focus {
        //     background-image: none;
        //     opacity: 0 !important;
        // }
    }
}

.back {
    transform: rotate(180deg);
    .rtl & {
        transform: rotate(0deg);
    }
}

// .drop-ghost {
//     box-sizing: content-box;
// }