.modal {
    transition: all 0.5s ease-out, z-index 0s linear;
    backdrop-filter: blur(10px) saturate(180%);
    -webkit-backdrop-filter: blur(10px) saturate(180%);
    background-color: rgba(20, 30, 40, 0.4);
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999;
    top: 0;
    left: 0;
    overflow: hidden;
    &.closing {
        transition: all 0.2s ease-out, z-index 0s linear;
        backdrop-filter: blur(0px) saturate(100%);
        background-color: transparent;
        .modalContent {
            animation: fadeout 0.5s;
            animation-fill-mode: forwards;
        }
    }
    .modalContent {
        position: relative;
        transition: all 0.5s;
        margin: auto;
        border: 0px solid #888;
        width: 80%;
        overflow: auto;
        border-radius: 8px;
        max-height: 100%;
        margin-top: 20px;
        // padding-top: 5%;
        animation: fadein 0.5s;
        width: 100%;
        display: flex;
        justify-content: center;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
            display: none;
        }
    }
}