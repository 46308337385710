.notifManager {
    position: fixed;
    right: 0px;
    padding-right: 20px;
    top: 0px;
    z-index: 1000;
    max-height: calc(100vh);
    overflow: auto;
    .notifBox {
        background-color: #ffffffdd;
        backdrop-filter: blur(10px) saturate(180%);
        -webkit-backdrop-filter: blur(10px) saturate(180%);
        border-radius: 4px;
        padding: 3px 3px;
        max-width: 200px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        animation: rightAppear 0.5s;
        transition: height 0.5s;
        // transition: all 0.5;
        &:first-child {
            margin-top: 60px;
        }
        .notifTitle {
            font-size: 14px;
        }
        .notifDescription {
            font-size: 12px;
            opacity: 0.8;
        }
        &.rightHide {
            animation: rightHide 0.5s;
            animation-fill-mode: forwards;
        }
        &.success {
            background-color: #00ab66cc;
            color: #fff;
        }
        &.error {
            background-color: #ee5050cc;
            color: #fff;
        }
    }
}