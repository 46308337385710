.listItemChildIndicator {
    opacity: 0.2;
    transition: all 0.3s;
    transform: rotate(90deg);
    &.open {
        transform: rotate(0deg);
    }
}

.collapse {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.7s ease-out;
    height: auto;
    &.open {
        overflow: visible;
        max-height: 600vh;
        transition: max-height 0.4s ease-out;
    }
    &.close {
        max-height: all 0.4s ease-out;
    }
}

.tabBar {
    transition: all 0.5s;
    background-color: #fff; // rgba(10, 10, 10, 1);
    // height: 100vh;
    .tabBarListItems {
        // transition: all 0.5s;
        border-radius: 0px;
        // width: 100%;
        position: relative;
        text-align: center;
        padding: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .ListItemName {
            // transition: all 0.5s;
            // margin: 0px 10px;
            color: "#202020";
            font-size: 12px;
            margin-top: 5px;
        }
        &:first {
            margin-top: 100px;
        }
        &:hover {
            .tabBarListItemsImg {
                opacity: 1;
            }
        }
    }
    .tabBarListItemsImg {
        width: 25px;
        opacity: 0.3;
        transition: all 0.5s;
    }
    .tabBarLogo {
        background-color: #fff;
        position: sticky;
        z-index: 10;
        top: 0px;
        transition: all 0.5s;
        // min-height: 70px;
        height: 60px;
        padding-top: 10px;
        // display: flex;
        // justify-content: center;
    }
    .notif {
        position: absolute;
        min-width: 22px;
        min-height: 22px;
        top: 5%;
        right: 5%;
        background-color: #ee5050;
        border-radius: 30px;
        padding: 1px 7px;
        color: #fff;
        font-size: 12px;
        .rtl & {
            left: 5%;
            right: auto;
        }
    }
    .tabBarListChildren {
        display: none;
        // padding: 10px;
        // width: 100%;
        position: fixed;
        left: 80px;
        top: 80px;
        // left: 100%;
        background-color: #ffffffff;
        opacity: 1;
        max-height: 90vh;
        overflow: auto;
        // z-index: 100;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .tabBarListItems {
            padding: 5px 15px;
        }
        .tabBarListItemsImg {
            display: none;
        }
        // .tabBarListChildrenContainer{
        // }
        .listItemChildIndicator {
            // position: relative;
            // bottom: 0;
            transform: rotate(0deg);
            &.open {
                transform: rotate(90deg);
            }
        }
        .tabBarListItemsImg {
            width: 25px;
        }
        // .collapse{
        //     transition: max($numbers: );
        // }
        .tabBarListChildrenContainer {
            padding-left: 0px;
        }
        .tabBarListChildren {
            display: block;
            position: relative;
            background-color: transparent;
            box-shadow: none;
            left: 0;
            top: 0;
            padding-left: 12px;
            height: auto;
            // height: 100%;
            max-height: none; // max-height: 85vh;
            &.open {
                overflow: visible;
                // width: auto;
            }
            // top: 400;
            // left: 100%;
        }
        .tabBarListItems {
            display: flex; //!important;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            .ListItemName {
                margin: 0px 8px;
            }
        }
        &.open {
            display: block;
            // width: auto;
        }
    }
    &.desktop {
        position: sticky;
        z-index: 10;
        top: 0;
        height: 100vh;
        width: 80px;
        top: 0;
        border-right: 1px #eee solid;
        border-left: 1px #eee solid;
        .tabsList {
            transition: all 0.5s;
            // height: 100vh;
            display: flex;
            margin-top: 15px;
            // justify-content: center;
            align-items: center;
            flex-direction: column;
            // padding-top: 120px;
        }
        &.tabBarOpen {
            min-width: 240px;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
            .tabsList {
                justify-content: flex-start; // height: auto;
                // align-items: flex-start;
            }
            .listItemChildIndicator {
                transform: rotate(0deg);
                &.open {
                    transform: rotate(90deg);
                }
            }
            .tabBarLogo {
                position: sticky;
                z-index: 10;
                margin-top: 0px;
                top: 0px;
                margin-bottom: 10px;
                background-color: #e2e8f2;
                padding: 15px 10px;
                height: 130px;
                border-bottom: 1px solid #e2e6ea;
            }
            .tabBarListChildrenContainer {
                padding-left: 12px;
                .rtl & {
                    padding-right: 12px;
                }
            }
            .tabBarOpenButton {
                transform: rotate(-180deg);
            }
            .tabBarListChildren {
                display: block;
                position: relative;
                left: 0;
                top: 0;
                z-index: 0;
                max-height: none;
                overflow: visible;
                // left: 200px;
                background-color: transparent;
                // opacity: 1;
                // z-index: 100;
                box-shadow: none; //0px 0px 30px rgba(0, 0, 0, 0.1);
                border-radius: 0px; //4px;
            }
            .tabBarListItems {
                width: 90%;
                flex-direction: row;
                justify-content: flex-start;
                border-radius: 30px;
                padding: 8px 15px;
                margin-bottom: 8px;
                .ListItemName {
                    margin: 0px 10px;
                    font-size: 13px;
                }
                .notif {
                    // position: relative;
                    top: 14px;
                }
            }
        }
    }
    &.mobile {
        position: fixed;
        bottom: 0;
        z-index: 10;
        width: 100%;
        // display: flex;
        // height: '100vh';
        width: 100%;
        left: 0;
        border-top: 1px #eee solid;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
        .tabBarLogo {
            display: none;
        }
        .tabBarListItemsImg {
            width: 25px;
        }
        .tabBarListChildren {
            display: block;
            position: fixed;
            left: 10px;
            right: 10px;
            top: auto;
            // left: 0;
            bottom: 85px;
            z-index: 100;
            max-height: calc(100vh - 150px);
            // overflow: auto;
            // width: 90%;
            // left: 200px;
            background-color: #ffffffff;
            border-radius: 8px;
            overflow: auto;
            // opacity: 1;
            // z-index: 100;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            .tabBarListItemsImg {
                width: 25px;
            }
            // border-radius: 0px; //4px;
            .listItemChildIndicator {
                position: relative;
                // bottom: 0;
                transform: rotate(0deg);
                &.open {
                    transform: rotate(90deg);
                }
            }
            .tabBarListChildren {
                position: relative;
                bottom: 0;
                left: 0;
                top: auto;
                box-shadow: none;
                overflow: visible;
                background-color: transparent;
                backdrop-filter: none;
                -webkit-backdrop-filter: none;
                max-height: none;
                // position: relative;
                // padding-left: 12px;
                // max-height: 90vh;
                // overflow: auto;
                // * {
                //     background-color: transparent;
                //     box-shadow: none;
                // }
                // border-radius: 50px;
                // background-color: transparent !important;
                width: 95%;
            }
        }
        .tabBarOpenButton {
            display: none;
        }
        .tabsList {
            display: flex;
            // justify-content: center;
            align-items: center;
            // bottom: 0;
            // left: 0;
        }
        .listItemChildIndicator {
            position: absolute;
            bottom: 0;
            transform: rotate(90deg);
            &.open {
                transform: rotate(-90deg);
            }
        }
    }
    .tabBarOpenButton {
        opacity: 1;
        position: absolute;
        bottom: 10px;
        right: 18px;
        transition: all 0.5s;
        background-color: #fff;
        border-radius: 80px;
        padding: 5px 5px;
        // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        img {
            opacity: 0.3;
            transition: all 0.5s;
            &:hover {
                opacity: 1;
            }
        }
    }
}