.default-theme {
    .description-text {
        color: #789;
    }
    .text-description {
        color: #789;
    }
    .link-text {
        color: #1787fc;
    }
    .modal-body {
        background-color: #fff;
        border-radius: 4px;
    }
}

.lined-table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 13px;
    font-weight: normal;
    color: #101520;
    th {
        border: 1px solid #ddd;
        background-color: #e6e8ea;
        padding: 6px 10px;
    }
    td {
        border: 1px solid #eee;
        padding: 5px 10px;
    }
    * {
        font-weight: normal;
    }
    thead tr th {
        font-weight: bold !important;
    }
}