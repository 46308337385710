.pageSide {
    // box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.15);
    background-color: #f2f6f8;
    min-height: 100vh;
    position: relative;
    top: 0;
    padding-top: 20px;
    padding-bottom: 50px;
    .pageSideOption {
        font-size: 14px;
        width: 100%;
        text-align: start;
        border-radius: 0px;
        padding: 15px 10%;
        margin: 0px;
        &:hover {
            background-color: #e2e8f2;
        }
        &.selected {
            background-color: #677dc4;
            color: #fff;
            // font-weight: bold;
        }
    }
    // @media only screen and (max-width: $medium) {
    //     position: absolute;
    //     top: 0px;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 1;
    //     padding-top: 50px;
    // }
}