.sigma-bottom {
    // border: 1px dashed #9ab;
    position: relative;
    top: 0.885em;
    font-size: 70%;
    // border-radius: 4px;
    // position: relative;
    .sigma-bottom-content {
        padding: 0px 5px;
        min-width: 30px;
        min-height: 10px;
        outline: none;
        border: 1px dashed #cde;
        border-radius: 4px;
        &:hover {
            border: 1px dashed #9ab;
        }
        &:focus {
            border: 2px solid #007affaa;
        }
        // outline-color: #007affaa;
    }
}

.sigma-front {
    min-width: 40px;
    min-height: 20px;
    // position: relative;
    font-size: 100%;
    // margin-left: 2px;
    // top: 0.885em;
    // padding: 0px 5px;
    // font-size: 70%;
    .sigma-front-content {
        padding: 2px 5px;
        min-width: 50px;
        z-index: 10;
        border-radius: 4px;
        // margin: 3px 0px;
        outline: none;
        border: 1px dashed #cde;
        &:hover {
            border: 1px dashed #9ab;
        }
        // outline-color: #007affaa; // outline: 1px blue;
        &:focus {
            border: 2px solid #007affaa;
        }
    }
}

.equation-container {
    // border: 1px dashed #f2f6f8;
    padding: 2px 4px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 4px;
    padding-bottom: 12px;
    padding-top: 5px;
    position: relative;
}

.equation-container {
    font-family: math !important;
    // outline: none;
}

.main-sign {
    font-size: 120%;
    font-weight: bold;
    font-family: 'poppins';
}

.equation-close-but {
    width: 14px;
    height: 14px;
    background-color: #789;
    font-size: 10px;
    border-radius: 20px;
    position: absolute;
    right: -8px;
    top: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif !important;
    display: none;
    cursor: pointer;
    filter: invert(100%);
}

.equation-container:hover .equation-close-but {
    display: flex;
}

.placeholder {
    color: #9ab;
    font-size: 70%;
}