$sideWidth: 240px;
$miniWidth: 200px;
.diagram {
    .digramSidePanel {
        box-shadow: 0px 0px 15px #00051010;
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 8;
        height: calc(100vh - 50px);
        transition: all 0.5s;
        &.sideClose {
            transform: translateX(-$sideWidth);
            .diagramSideHandler {
                img {
                    transform: rotate(0deg);
                }
            }
        }
        // overflow: auto;
        .digramSidePanelList {
            width: $sideWidth;
            padding: 10px 15px;
            background-color: #ffffffcc;
            overflow: auto;
            z-index: 1;
            height: 100%;
        }
        .diagramSideHandler {
            position: absolute;
            right: -30px;
            background-color: #ffffffcc;
            top: 30px;
            width: 30px;
            height: 60px;
            border-radius: 0px 10px 10px 0px;
            box-shadow: 0px 0px 15px #00000010;
            z-index: -1;
            transition: all 0.5s;
            &:hover {
                background-color: #ffffffcc;
                img {
                    opacity: 1;
                }
            }
            img {
                transform: rotate(180deg);
                transition: all 0.5s;
                opacity: 0.5;
            }
        }
    }
    .miniViewContainer {
        position: fixed;
        bottom: 15px;
        right: 5px;
        padding: 10px;
        z-index: 10;
        display: flex;
        align-items: flex-end;
        transition: all 0.5s;
        &.miniClose {
            transform: translateX($miniWidth + 60px);
            .zoomToggleBut {
                transform: translateX(-20px) rotate(180deg); //-20
                // background-color: #000000cc;
                filter: invert(100%);
                // opacity: 1;
            }
        }
        .zoomToggleBut {
            transform: rotate(0deg);
            background-color: #ffffffcc;
            box-shadow: 0px 0px 15px #00000010;
            border-radius: 4px;
            padding: 5px;
            transition: all 0.5s;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
        .miniView {
            width: $miniWidth;
            height: 150px;
            background-color: #ffffffdd;
            border-radius: 4px;
            position: relative;
            box-shadow: 0px 0px 15px #00051010;
        }
        .rigramDroppable {
            -ms-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            // transition: all 0.5s;
        }
        .mini-view-tracker {
            width: 200px;
            height: 150px;
            background-color: rgba(10, 20, 30, 0.1);
            border: 1px solid #ffffffaa;
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .zoomBut {
            opacity: 0.4;
            transition: all 0.5s;
            &:hover {
                opacity: 1;
            }
        }
    }
    .moreBut {
        opacity: 0.7;
        transition: all 0.5s;
        pointer-events: all;
        &:hover {
            opacity: 1;
        }
    }
    .contactBut {
        pointer-events: all;
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: 1px solid #789;
        border-radius: 10px;
        transition: all 0.5s;
        box-shadow: 0px 0px 5px #44556620 inset;
        &:hover {
            background-color: #abc;
        }
    }
    .pathRemoveBut {
        height: 34px;
        width: 34px;
        background: #123;
        border: 1px solid #101010;
        border-radius: 30px;
        transform: translate(-50%, -50%);
        position: absolute;
        box-shadow: 0px 0px 15px rgba(10, 20, 30, 0.1);
        cursor: pointer;
        // display: none;
        opacity: 0;
        transition: opacity 0.3s;
        &.hovered {
            // display: block;
            opacity: 1;
        }
    }
}