$background: #e2eaf2;
$background2: #f2f6f8;
$buttonColor: rgb(57, 111, 180);
$white: #ffff;
.editor_select_category {
    margin-top: 10px;
    position: relative;
    &_button {
        margin-top: 10px;
        width: 100%;
        height: 3.4rem;
        background-color: $background2;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        align-items: center;
    }
    &_title {
        color: black;
        font-weight: bold;
        font-size: 20;
    }
    &_options {
        &_container {
            /* display: flex; */
            width: 100%;
            margin-top: 10px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            /* flex-flow: column; */
            /* justify-content: space-around; */
            /* align-items: center; */
            /* border: 1px solid black; */
            background-color: $white;
            border-radius: 8px;
            position: absolute;
            width: 100%;
            /* height: 200px; */
            /* box-sizing: border-box; */
            label {
                background-color: $white;
                width: 100%;
                border-radius: 8px;
                color: black;
                padding: 1rem;
                cursor: pointer;
                &:hover {
                    background-color: $background2;
                }
            }
            input {
                display: none;
            }
        }
        &_holder {
            width: 100%;
            margin-top: 2px;
            /* height: 100; */
            display: flex;
            flex-flow: column;
        }
    }
}

.hidden {
    display: none;
}

.choose_file_section {
    background: $background;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    /* width: 100%; */
    border-radius: 8px 8px 0 0;
    padding: 10px;
    &_text {
        width: 50%;
        display: flex;
        flex-flow: row nowrap;
        justify-items: center;
        justify-content: space-between;
        position: relative;
        align-items: center;
        &_ImportData {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            width: 300px;
            position: relative;
            img {
                position: absolute;
                left: 48%;
            }
        }
        &_guidance {
            display: none;
            z-index: 1;
            padding: 10px;
            position: absolute;
            width: 300px;
            left: 110%;
            border-radius: 8px;
            height: max-content;
            background: #1b4479;
            text-align: justify;
            color: $white;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            overflow: hidden;
            font-size: small;
        }
    }
    &_icon {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        label {
            color: $white;
            background: $buttonColor;
            border-radius: 6px;
            padding: 7px 10px;
            font-size: 12px;
            width: 100px;
            text-align: center;
        }
    }
    &_submit_icon_holder {
        background-color: $background;
        /* padding: 10px 0; */
        margin-top: 50px;
        border-radius: 0 0 8px 8px;
        display: flex;
        flex-flow: row nowrap;
        justify-items: end;
        justify-content: end;
        padding: 10px 15px;
    }
    &_submit_icon {
        display: flex;
        flex-flow: row nowrap;
        label {
            color: $white;
            background: $buttonColor;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 6px;
            padding: 7px 10px;
            font-size: 12px;
            width: 100px;
            text-align: center;
        }
    }
}

.excel_disaply_data_section {
    background-color: $white;
    height: 200px;
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    position: relative;
    &_all_variables {
        border: 1px solid #396fb4;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        width: 45%;
        display: flex;
        flex-flow: column;
        align-items: center;
        border-radius: 8px;
        overflow-y: auto;
        position: relative;
    }
    &_selected_variables {
        width: 45%;
        border: 1px solid #396fb4;
        border-radius: 8px;
        display: flex;
        flex-flow: column;
        align-items: center;
        overflow-y: auto;
        position: relative;
        tr:nth-child(even) {
            background: $background2;
        }
    }
}

.information_icon_excel {
    width: 22px;
    height: 22px;
}

.selectExcelFileInput {
    display: none;
}

.submitExcelFile {
    display: none;
}

.submitGeneratedModel {
    display: none;
}

.excel_variables_checkmark {
    background-color: #eee;
}

.transfer_data_arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
    &_holder {
        display: flex;
        flex-flow: column;
        justify-items: center;
        align-items: center;
        position: relative;
    }
    &One {
        top: 45%;
    }
    &Two {
        top: 50%;
        transform: rotate(180deg);
    }
}

.single_displayed_variable {
    img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
    td {
        margin-left: 15px;
        cursor: default;
        padding: 0px 5px;
    }
    span {
        margin-left: 15px;
    }
}

.data_Values_display_container {
    // display: none;
    // left: 5%;
    background: $white;
    /* top: 100px; */
    position: relative;
    width: 150%;
    // margin-right: 50%;
    // top: 0;
    // left: 0;
    // bottom: 0;
    right: 25%;
    // margin: auto;
    height: 60vh;
    /* border: 1px solid #396FB4; */
    border-radius: 8px 8px;
    /* padding: 8px; */
    /* background-color: aquamarine; */
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    &_header {
        border-radius: 8px 8px 0 0;
        width: 100%;
        height: 10%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        background-color: $background;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 14px;
        font-weight: bolder;
        div {
            width: 50%;
            input {
                width: 80%;
                padding: 5px;
                border: none;
                border-radius: 2px;
            }
        }
    }
    &_table {
        width: 100%;
        height: 80%;
        overflow: auto;
        table {
            width: 100%;
            table-layout: fixed;
            overflow-y: scroll;
            tr {
                padding: 20px;
                font-size: 12px;
            }
            tbody tr:nth-child(odd) {
                background-color: $background2;
            }
            tbody tr:nth-child(even) {
                background-color: $background;
            }
        }
        tr td {
            text-align: left;
            padding: 8px;
            width: 90px;
            text-align: center;
            font-weight: bolder;
            overflow: hidden;
            /* display: ; */
        }
    }
    &_buttons {
        width: 100%;
        background-color: $background;
        height: 10%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        // padding-right: 5%;
        border-radius: 0 0 8px 8px;
        button {
            color: $white;
            background-color: $buttonColor;
            border-radius: 8px;
            padding: 5px 10px;
            margin-left: 5px;
        }
        div {
            width: 100%;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            .data_Values_display_container_buttons_innerDiv {
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
            }
        }
    }
}

#name_index {
    overflow: visible;
    white-space: nowrap;
}

.selectAll_deleteAll_btns_div {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $white;
    width: 100%;
    height: 20%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 19%;
    div {
        cursor: pointer;
        font-weight: 700;
    }
}
.generated_model_container {
    .header {
        background: $background;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        /* width: 100%; */
        border-radius: 8px 8px 0 0;
        padding: 10px;
        .text {
            width: 50%;
            display: flex;
            flex-flow: row nowrap;
            justify-items: center;
            justify-content: space-between;
            position: relative;
            align-items: center;
            .ImportData {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                width: 300px;
            }
            &_guidance {
                display: none;
                z-index: 1;
                padding: 10px;
                position: absolute;
                width: 300px;
                left: 110%;
                border-radius: 8px;
                height: max-content;
                background: #1b4479;
                text-align: justify;
                color: $white;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
                overflow: hidden;
                font-size: small;
            }
        }
        &_icon {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            label {
                color: $white;
                background: $buttonColor;
                border-radius: 6px;
                padding: 7px 10px;
                font-size: 12px;
                width: 100px;
                text-align: center;
            }
        }
    }
    .footer {
        min-height: 20px;
        background-color: $background;
        border-radius: 0 0 8px 8px;
        display: flex;
        flex-flow: row nowrap;
        padding: 10px 20px;
        justify-content: space-between;
        .download{
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            background-color: #396fb4;
            border-radius: 1rem;
            padding: 0.5rem 0.8rem ;
            color: #fff;
            .icon{
                width: 1.3rem;
                height: 1.0rem;
                margin-right: 0.2rem;
            }
        }
        .solve {
            cursor: pointer;
            color: #ffff;
            background: #396fb4;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 6px;
            padding: 7px 10px;
            font-size: 12px;
            width: 100px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.editor_pagination {
    display: grid !important;
    grid-template-columns: 1fr 3fr 1fr 1fr 3fr !important;
    list-style: none;
    width: 30%;
    &_numbers {
        a:hover {
            background-color: $background2;
        }
    }
    &_numbers_holder {
        grid-template-columns: 2/3 !important;
        display: flex;
        flex-flow: row;
        // justify-content: flex-start;
    }
    a {
        text-decoration: none;
        color: black;
        font-size: 12px;
        border-radius: 10%;
        transition: background-color 0.3s;
        // border: 1px solid $white;
        margin: 0 4px;
        padding: 4px 16px;
    }
    .activePaginationPage {
        background-color: $buttonColor;
    }
    &_arrows:active {
        background-color: $background2;
    }
    &_dots {
        grid-template-columns: 3/4;
    }
    &_arrows_prev {
        grid-template-columns: 1/2;
        text-align: center;
    }
    &_arrows_next {
        grid-template-columns: 4/5;
        text-align: center;
    }
}

@media (max-width: 770px) {
    .editor_tools_box {
        margin-top: 2rem;
    }
}

@media (max-width: 500px) {
    .excel_disaply_data_section {
        .single_item_name {
            font-size: 11px;
        }
    }
    .data_Values_display_container {
        width: 100%;
        right: 0;
    }
}

@media (max-width: 1050px) {
    .data_Values_display_container {
        width: 100%;
        right: 0;
    }
}

// EXCEL IMPORT CLASSNAMES AND STYLES

.editor__excelWBNameModal {
    background-color: #e2eaf2;
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 10px;
    .header {
        flex: 1;
        border-bottom: 1px solid #aab3be;
    }
    .body {
        flex: 20;
        display: flex;
        flex-flow: column nowrap;
        .name--row:nth-of-type(odd) {
            background-color: #1b4479;
            color: #fff;
        }

        .name--row:nth-of-type(even) {
            background-color: #fff;
        }
        .name--row {
            display: flex;
            justify-items: center;
            align-items: center;
            .name--title {
                flex: 2;
                display: flex;
                flex-flow: row;
                justify-content: center;
                align-items: center;
                padding: 5px 10px;
            }
            .name--value {
                padding: 5px 10px;
                flex: 1;
                display: flex;
                flex-flow: row;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .footer {
        flex: 1;
        border-top: 1px solid #aab3be;
        border-radius: 0 0 10px 10px;
        display: flex;
        align-items: center;
        .pagination__activeButton {
            color: white;
            background: #26a69a;
        }

        .pagination__inactiveButton {
            color: #2c3e50;
            background: #ef5350;
        }
        .pagination {
            // background-color: $color3;
            // padding: 8px 0px;
            font-weight: 500;
            text-align: left;
            font-size: 16px;
            color: #2c3e50;
            // border-bottom-left-radius: 15px;
            // border-bottom-right-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            // min-height: 50px;
        }

        .button {
            border: none;
            padding: 7px 14px;
            border-radius: 10px;
            cursor: pointer;
            margin-right: 4px;
            margin-left: 4px;
            min-width: 70px;
        }

        .pagination__activeButton {
            color: white;
            background: #1b4479;
        }

        .pagination__inactiveButton {
            color: #2c3e50;
            background: #fff;
        }
        .confirm__cancel__generate {
            flex: 1;
            display: flex;
            flex-direction: row-reverse;
            justify-content: end;
            align-items: center;

            .positive {
                background-color: #1b4479;
                border: 1px solid #396fb4;

                margin-left: 10px;
                // min-height: 30px;
                padding: 7px 14px;
                min-width: 100px;
                border-radius: 10px;
                color: #fff;
                margin-right: 5%;
                cursor: pointer;
            }
            .negative {
                border: 1px solid #396fb4;
                // padding: 5px 10px;
                padding: 7px 14px;
                min-width: 100px;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
}

.editorVariableSelectInput {
    display: flex;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .selectOptions {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .selectedValue {
        display: flex;
        justify-content: flex-start;
        color: #757575;
        align-items: center;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 5%;
        background-color: #ffff;
        font-size: 0.8rem;
        border-radius: 8px;
    }
    .list {
        width: 100%;
        top: 100%;
        position: absolute;
        z-index: 3;
    }
    .divClassName {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 100%;
        padding: 10px 10px;
        background-color: #dedfed;
        border-radius: 8px;
        margin-top: 5px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        z-index: 10;
        position: relative;
        color: #000;
    }
    .labelClassName {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 0.8rem;
    }
}
