.table {
    position: relative;
    &.defaultTable {
        .tableHeader {
            background-color: #f0f4f5cc;
            text-align: center;
            border-radius: 2px 2px 0px 0px;
            min-width: 100%;
            will-change: transform;
            border: 1px solid #eee;
            border-bottom: none;
            font-size: 14px;
            color: #456;
            // z-index: 1;
            // box-sizing: content-box !important;
            width: auto;
            position: relative;
            // position: sticky;
            top: 0px;
            div:first-child {
                text-align: start;
                padding-left: 20px !important;
                z-index: 2;
                position: sticky;
                position: -webkit-sticky;
                position: -moz-sticky;
                position: -ms-sticky;
                position: -o-sticky;
                left: 0px;
                background-color: #eaf0f2dd;
                border-right-color: #e0e2e8aa !important;
                border-left-color: #e0eaf0aa !important;
                .rtl & {
                    padding-right: 20px !important;
                    left: auto;
                    right: 0;
                }
            }
            .tableHeaderItem {
                // background-color: #f2f6f8;
                padding: 7px 10px;
                font-weight: bold;
                // height: 100%;
                border-bottom: 1px solid #eee;
                border-right: 1px solid #fff;
                position: relative;
                transition: background-color 0.5s;
                display: block;
                .rtl & {
                    border-right: none;
                    border-left: 1px solid #fff;
                }
                .resizer {
                    /* Displayed at the right side of column */
                    position: absolute;
                    top: 0;
                    right: -1px;
                    width: 4px;
                    height: 100%;
                    cursor: ew-resize;
                    user-select: none;
                    transition: all 0.3s;
                    background-color: transparent;
                    // border-radius: 10px;
                    z-index: 1;
                    .rtl & {
                        left: -1px;
                        right: auto;
                    }
                    .tableHeaderItem & {
                        background-color: rgba(200, 227, 255, 1);
                        // &:hover {
                        //     background-color: rgba(200, 227, 255, 1) !important;
                        // }
                    }
                    // border-right: 2px solid transparent;
                    &.activedResizer {
                        background-color: rgba(109, 175, 247, 1) !important;
                        // border-right: 2px solid #007aff50;
                    }
                }
                &:hover {
                    .resizer {
                        background-color: rgba(200, 227, 255, 1);
                    }
                }
            }
            // .stickyTableHeadt{
            //     // z-index: 2;
            //     // position: sticky;
            //     // position: -webkit-sticky;
            //     // position: -moz-sticky;
            //     // position: -ms-sticky;
            //     // position: -o-sticky;
            //     // left: -5px;
            //     // background-color: #eaf0f2dd;
            //     // border-right-color: #e0e2e8aa !important;
            //     // border-left-color: #e0eaf0aa !important;
            //     .rtl & {
            //         left: auto;
            //         right: -5px;
            //         // position: fixed;
            //         // top: 0px
            //     }
            //     // .Safari & {
            //     //     // .rtl & {
            //     //     position: "relative" !important;
            //     //     // }
            //     // }
            // }
            &.isResizingTable {
                * {
                    user-select: none;
                }
                .resizer {
                    background-color: transparent !important;
                }
            }
        }
        .tableBody {
            // margin-top: 7px;
            border: 1px solid #f2f6f8;
            min-width: 100%;
            .tableRow {
                // transition: all 0.5s;
                text-align: center;
                display: flex;
                // align-items: flex-end;
                align-items: stretch;
                min-width: 100%;
                // box-sizing: content-box;
                position: relative;
                .tableBodyItem:first-child {
                    text-align: start;
                    padding-left: 20px !important;
                    background-color: #f8fafbdd;
                    z-index: 1;
                    // box-shadow: 1px 0px 5px 0px rgba(0, 5, 10, 0.05);
                    position: sticky;
                    position: -webkit-sticky;
                    position: -moz-sticky;
                    position: -ms-sticky;
                    position: -o-sticky;
                    left: 0px;
                    backdrop-filter: blur(10px) saturate(180%);
                    -webkit-backdrop-filter: blur(10px) saturate(180%);
                    .rtl & {
                        padding-right: 20px !important;
                        left: auto;
                        right: 0;
                    }
                }
            }
            .tableBodyItem {
                border-bottom: 1px solid #f2f6f8;
                padding: 7px 10px;
                font-size: 15px;
                // height: 100%;
                flex: 1;
                // height: 100%;
                border-right: 1px solid #f2f6f8;
                transition: background-color 0.5s;
                display: flex;
                flex-direction: column;
                justify-content: center;
                // height: 100%;
                .rtl & {
                    border-right: none;
                    border-left: 1px solid #f2f6f8;
                }
            }
            // .stickytableBodyItem {
            //     .rtl & {
            //         left: auto;
            //         right: -5px;
            //     }
            // }
        }
    }
    .tableScroll {
        position: sticky;
        position: -webkit-sticky;
        bottom: 10px;
        text-align: center;
        background-color: #f2f6f8;
        border-radius: 20px;
        padding: 0px;
        z-index: 3;
        overflow: hidden;
        // box-shadow: 0px 0px 5px #00000020;
        // border: 1px solid #f0f2f6;
        .scrollIndicator {
            background-color: #d0d8dacc;
            height: 10px;
            border-radius: 20px;
            transition: all 0.5s;
        }
        &:hover {
            .scrollIndicator {
                background-color: #bbbebf;
            }
        }
        // opacity: 0.8;
        // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
    .rtl & {
        &.Safari {
            .tableHeaderItem {
                position: relative !important;
                left: auto;
                right: 0 !important;
            }
            .tableBodyItem {
                position: relative !important;
                left: auto;
                right: 0 !important;
            }
        }
    }
}