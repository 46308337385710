.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: relative;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    background-color: #007aff10;
    border: 1px solid #007aff30;
    transition: all 0.5s;
}

.checkbox-container:hover input~.checkmark {
    background-color: #2196f350;
    border: 1px solid #2196f350;
}

.checkbox-container input:checked~.checkmark {
    background-color: #2196f3;
    border: 1px solid #2196f3;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 34%;
    top: 16%;
    width: 30%;
    height: 60%;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.radioMark {
    position: relative;
    height: 17px;
    width: 17px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    transition: all 0.5s;
}

.checkbox-container:hover input~.radioMark {
    background-color: #2196f350;
    border: 1px solid #2196f350;
}

.checkbox-container input:checked~.radioMark {
    background-color: #2196f3;
    border: 1px solid #2196f3;
}

.radioMark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.radioMark:after {
    display: block;
}

.checkbox-container .radioMark:after {
    left: 25%;
    top: 25%;
    width: 48%;
    height: 48%;
    border: solid #ffffff20;
    background-color: #ffffffee;
    border-radius: 20px;
    border-width: 2px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: 0px 0px 1px #00000010 inset;
}

.page-loader table {
    table-layout: fixed;
    width: 100%;
}

.page-loader figure {
    margin: 0px;
}

.page-loader img {
    width: 100% !important;
}

.page-loader table {
    border-spacing: 0em;
    margin-top: 15px;
    margin-bottom: 25px;
}

.page-loader td {
    border: 1px solid #f2f6f8;
    margin: 0px;
    padding: 5px 10px;
}

.page-loader tr:nth-child(even) {
    background-color: #f6f8fa;
}

.rtl {
    .page-loader {
        direction: rtl;
    }
}

.ltr {
    .page-loader {
        direction: ltr;
    }
}